import React from 'react';

import { Box } from '@core';
import { Icon, Text } from '@components';

const CTAFeatures = ({ children }) =>
  children.map(({ title }, index) => (
    <Box display="flex" alignItems="flex-start" mb={8} key={index}>
      <Box pt={6}>
        <Icon.Check size={20} color="success" />
      </Box>
      <Text.Body2 ml={12}>{title}</Text.Body2>
    </Box>
  ));

export default CTAFeatures;
